import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(
        private http: HttpClient
    ) { }

    getAll(searchParams) {
        return this.http.get(`${environment.baseUrl}/company`, { params: searchParams });
    }

    get(id) {
        return this.http.get(`${environment.baseUrl}/company/` + id);
    }

    edit(id, formData) {
        return this.http.put(`${environment.baseUrl}/company/` + id, formData);
    }

    create(formData) {
        return this.http.post(`${environment.baseUrl}/company`, formData);
    }

    delete(id) {
        return this.http.delete(`${environment.baseUrl}/company/` + id);
    }

}
